<template>
  <div class="flow-chart-container">
    <div class="flow-chart">
        <div class="flow-column">
        <div class="flow-item">
          <div class="flow-content">
            <h3>申请人</h3>
            <p>姓名：{{ info.sqr || '--' }}</p>
            <p>状态： <span :class="{'status': true, 'pending': info.lzzt==0, 'accepted': info.lzzt==1}">{{ info.lzzt==0?'未提交':'已提交' }}</span></p>
            <p>时间：{{ info.createTime || '--' }}</p>
          </div>
        </div>
        <div class="flow-arrow" v-if="groupedCheckList.length"></div>
      </div>
      <div v-for="(column, columnIndex) in groupedCheckList" :key="columnIndex" class="flow-column">
        <div v-for="(item, itemIndex) in column" :key="itemIndex" class="flow-item">
          <div :class="['flow-content', {'end-node': item.shjb === 100}]" :title="'审核说明：'+item.shsm||''">
            <h3>{{ item.jbmc }}</h3>
            <p>{{item.shjb==0||item.shjb==100?'处理人':'审核人'}}： {{ item.shxm }}{{ item.fzr === 1 ? ' (负责人)' : '' }}</p>
            <p>状&emsp;态： <span v-html="shzt(item.shjb, item.shzt)"></span></p>
            <p>时&emsp;间： {{ item.shjs||'--' }}</p>
            <p class="opinion">意&emsp;见： {{ item.shsm||'--' }}</p>
          </div>
        </div>
        <div v-if="columnIndex !== groupedCheckList.length - 1" class="flow-arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckFlow',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    checkList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      // 数据属性
      innerCheckList: []
    }
  },
  computed: {
    groupedCheckList() {
      const grouped = {};
      this.innerCheckList.forEach(item => {
        if (!grouped[item.shjb]) {
          grouped[item.shjb] = [];
        }
        grouped[item.shjb].push(item);
      });
      return Object.values(grouped);
    }
  },
  watch: {
    checkList(newVal, oldVal) {
        // 判断是否是数组   
        if (Array.isArray(newVal)) {
            this.innerCheckList = newVal;
        } else {
            console.error('checkList 必须是数组');
        }
    }
  },
  methods: {
    // 方法
    
    shzt(level, val){
      if(level == 0 || level == 100){
        switch(val) {
          case '0':
            return '<span class="status pending">待处理</span>'
          case '1':
            return '<span class="status accepted">已受理</span>'
        }
      } else{
        switch(val) {
          case '0':
            return '<span class="status pending">待审核</span>'
          case '1':
            return '<span class="status approved">同意</span>'
          case '2': 
            return '<span class="status rejected">拒绝</span>'
        }
      }
    },
  },
  mounted() {
    // 生命周期钩子
  }
}
</script>

<style>
.flow-chart-container {
  width: 100%;
  overflow-x: auto;
  padding: 15px 10px;
  background-color: #f7f9fc;
  display: flex;
  align-items: center;
  min-height: 300px; /* 或者使用 100% 如果父元素有明确的高度 */
  box-sizing: border-box;
}

.flow-chart {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  background-color: #f7f9fc; /* 添加这行，与容器背景色相同 */
}

.flow-column {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 40px;
  vertical-align: middle;
  flex-shrink: 0; /* 添加这行 */
}

.flow-column:last-child {
  margin-right: 0;
}

.flow-item {
  margin-bottom: 12px; /* 稍微减少项目间距 */
}

.flow-content {
  background-color: #ffffff;
  border-radius: 8px; /* 稍微减小圆角 */
  padding: 10px 12px; /* 增加内边距 */
  width: 200px; /* 减小宽度 */
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.flow-content:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.flow-content h3 {
  margin: 0 0 6px 0;
  font-size: 14px; /* 稍微减小字体 */
  color: #333;
  font-weight: 600;
}

.flow-content p {
  margin: 4px 0;
  font-size: 12px; /* 稍微减小字体 */
  color: #666;
  white-space: normal;
}

.flow-content .opinion {
  word-break: break-all;
}

.flow-arrow {
  position: absolute;
  right: -30px; /* 调整箭头位置 */
  top: 50%;
  transform: translateY(-50%);
  width: 14px; /* 稍微减小箭头大小 */
  height: 14px;
  background-color: #a3d5ff;
  clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
}

.status {
  padding: 1px 4px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
}

/* 状态颜色保持不变 */
/* 新增结束节点样式 */
.end-node {
  background-color: #e8f5e9;
  border: 2px solid #4caf50;
}

.end-node h3 {
  color: #2e7d32;
}

.end-node::after {
  content: '结束';
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 10px;
}

.status.pending {
  background-color: #ffd54f;
  color: #000;
}

.status.accepted {
  background-color: #81c784;
  color: #fff;
}

.status.approved {
  background-color: #4caf50;
  color: #fff;
}

.status.rejected {
  background-color: #e57373;
  color: #fff;
}
</style>
